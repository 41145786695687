import Image from "next/image"


import TwitterLogo from "@assets/img/social-media/twitter.svg";
import EmailLogo from "@assets/img/social-media/email.svg";
import FacebookLogo from "@assets/img/social-media/facebook.svg";
import InstagramLogo from "@assets/img/social-media/instagram.svg";
import YoutubeLogo from "@assets/img/social-media/youtube.svg";


export default function FooterHomeComponent() {
  const actualYear = new Date().getFullYear();
  return (
    <div className="cont_footer_home">
      <div className="cont_social_media">
        {/* <a href="https://www.twitter.com/channel/mercadoazulmexico" className="icon youtube" target="_blank">
          <Image src={TwitterLogo} alt="Twitter" />
        </a> */}
        <a href="https://www.facebook.com/mercadoazulmexico/" className="icon facebook" target="_blank" rel="noopener noreferrer">
          <Image src={FacebookLogo} alt="Facebook" width={32} height={32} />
        </a>
        <a href="https://www.instagram.com/mercadoazulmexico/" className="icon instagram" target="_blank" rel="noopener noreferrer">
          <Image src={InstagramLogo} alt="Instagram" width={32} height={32} />
        </a>
        <a href="https://www.youtube.com/@mercadoazulmexico" className="icon youtube" target="_blank" rel="noopener noreferrer">
          <Image src={YoutubeLogo} alt="Youtube" width={32} height={32} />
        </a>
        <a href="mailto:contacto@mercadoazul.com.mx?Subject" className="icon facebook" target="_blank" rel="noopener noreferrer">
          <Image src={EmailLogo} alt="Facebook" height={32} width={32} />
        </a>
      </div>
      <div id="contact-us" className="cont_copyright">
        <p>© Mercado Azul {actualYear}. Todos los derechos reservados</p>
        <p className="city">CDMX, México</p>
      </div>
    </div>
  )
}