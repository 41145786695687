import { useState, useEffect } from 'react';

const useScrollPosition = () => {
  const [yScrollPosition, setYScrollPosition] = useState(0);
  const [xScrollPosition, setXScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setYScrollPosition(window.scrollY);
      setXScrollPosition(window.scrollX);
    };

    // Add event listener when component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // empty dependency array means this effect runs only once after initial render

  return { xScrollPosition, yScrollPosition };
};

export default useScrollPosition;
